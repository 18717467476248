'use client' // this is increasing the bundle size
import { usePathname } from '@lib/navigation'
import omit from 'lodash/omit'
import NavBar from '@components/nav-bar'
import menuItemMapper from '@lib/mappers/menu-item-mapper'
import type { TypeNavBarBlockFields } from '@lib/generated-types'
import slugify from '@sindresorhus/slugify'

const NavBarBlock = ({ sectionLink, menuItems, alignment, ...props }: TypeNavBarBlockFields) => {
  return (
    <NavBar
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      menu={menuItems.map(menuItemMapper)}
      alignment={alignment}
      activePathname={usePathname()}
      {...omit(props, ['internalName'])}
    />
  )
}

export default NavBarBlock
